import React from 'react';
import classes from './bottomNavigation.css';

const bottomNavigation = (props) => (
    <div className={classes.Bottom}>
        <a className={classes.Email} href="mailto: hello@pontimer.com"> Contact us: hello@pontimer.com</a>
        <a className={classes.Facebook} href='https://www.facebook.com/coutndowntimer' rel='noopener noreferrer' target='_blank'><i className="fab fa-facebook-f"></i> Pontimer Facebook Page</a>
    </div>
);

export default bottomNavigation;