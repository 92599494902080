import React, { Component } from 'react';
import TimerCard from '../../Components/TimerCard/timerCard';
import classes from './timerContainer.css';

class TimerContainer extends Component {
    render() {
        return (
            <div className={classes.TimerContainer}>
                {this.props.timers.map((timer) =>
                    <TimerCard
                        key={timer.id}
                        delete={this.props.delete}
                        follow={this.props.follow}
                        displayImage={this.props.displayImage}
                        flipCard={this.props.filpCard}
                        changeDisplay={this.props.changeDisplay}
                        timer={timer}
                    />)}
            </div>
        )
    }
}

export default TimerContainer;