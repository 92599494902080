import React from 'react';
// import birthday from '../../../Assets/Images/love.gif';
import classes from './timerImage.css';


const timerImage = (props) => {
    return (
    <div className={classes.TimerImage}>
            {/* <div onClick={() => (props.follow(props.timerId))}><i className={classes.Heart} class="fas fa-heart" style={{ color: props.isFollow ? "#F76B8A" : "white"}}></i></div> */}
        <img onClick={() => (props.displayImage(props.timerId))} src={props.image} alt='timers'/>
    </div>
    )
}

export default timerImage;
