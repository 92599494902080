import React from 'react';
import classes from './comingSoon.css';
import Create from '../../Assets/DesignElements/create.png';



const comingSoon = (props) => {
    return (
        <div className={classes.Container}>
            <p className={classes.PageTitle}>Create Personal Timers</p>
            <p className={classes.Title}>Coming Soon!</p>
            <div className={classes.Text}> Here, you will be able to create your own, personal timers</div>
            <div className={classes.Images}><img src={Create} alt=''></img></div>
            <div className={classes.Examples}> <span><i class="fas fa-gift"></i> birthdays</span> <span><i class="fas fa-umbrella-beach"></i> vacations</span></div>
            <div className={classes.Examples}> <span><i class="fas fa-snowman"></i> holidays</span> <span><i class="fas fa-child"></i> family</span></div>
            <div className={classes.Examples}> <span><i class="fas fa-building"></i> work</span> <span><i class="fas fa-bus-alt"></i> school</span></div>
            <div className={classes.Examples}> <span><i class="fas fa-heart"></i> anniversaries</span> <span><i class="fas fa-paw"></i> pets</span></div>
            <div className={classes.Examples}> <span><i class="fas fa-video"></i> movies</span> <span><i class="fas fa-music"></i> concerts</span></div>
            <p className={classes.More}>and any other event you want...</p>
            <div className={classes.EmailContainer}>
                <div className={classes.EmailButton}>
                    <i class="far fa-envelope"></i>
                    <a href="mailto: hello@pontimer.com">Email me, when it's ready</a>
                </div>
            </div>
        </div>
    )
}

export default comingSoon;