import React, {Component} from 'react';
// import UnitChanger from '../../../Assets/Icons/white-chevron.png';
import classes from './countdownDisplay.css';
import numeral from 'numeral';

numeral.register('locale', 'hu', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
    abbreviations: {
        thousand: 'E',  // ezer
        million: 'M',   // millió
        billion: 'Mrd', // milliárd
        trillion: 'T'   // trillió
    },
    ordinal: function (number) {
        return '.';
    },
    currency: {
        symbol: ' Ft'
    }
});

const MINS_IN_SECONDS = 60;
const HOURS_IN_SECONDS = MINS_IN_SECONDS * 60;
const DAYS_IN_SECONDS = HOURS_IN_SECONDS * 24;
const YEARS_IN_SECONDS = DAYS_IN_SECONDS * 365;


class CountdownDisplay extends Component {

    
    // days = Math.abs(Math.floor(differenceArray[i] / 86400) + 1);
    // hours = Math.abs(Math.floor((differenceArray[i] % 86400) / 3600) + 1);
    // minutes = Math.abs(Math.floor(differenceArray[i] % 3600 / 60) + 1);
    // seconds = Math.abs(Math.floor(differenceArray[i] % 60));
    // Math.floor(Math.abs(this.props.differenceInSeconds / 86400 / 365))

    render() {
        let displayComponent = null;
        numeral.locale('hu');
        switch (this.props.display) {
            case 'seconds': 
                displayComponent = (
                    <span className={classes.TimerCountdown}>
                        {numeral(this.props.differenceInSeconds).format('0,0')}<span className={classes.Display}> sec</span>
                    </span>
                )
                break;
            case 'minutes':
                displayComponent = (
                    <span className={classes.TimerCountdown}>
                        {numeral(Math.floor(Math.abs(this.props.differenceInSeconds / 60)) * ((this.props.differenceInSeconds < 0) ? -1 : 1)).format('0,0')}<span className={classes.Display}> mins</span>
                    </span>
                )
                break;
            case 'heartbeats':
                displayComponent = (
                    <span className={classes.TimerCountdown}>
                        {numeral(Math.floor(Math.abs(this.props.differenceInSeconds / 60 * 70)) * ((this.props.differenceInSeconds < 0) ? -1 : 1)).format('0,0')} <i class="fas fa-heartbeat"></i>
                    </span>
                )
                break;
            case 'hours':
                displayComponent = (
                    <span className={classes.TimerCountdown}>
                        {numeral(Math.floor(Math.abs(this.props.differenceInSeconds / 3600)) * ((this.props.differenceInSeconds < 0) ? -1 : 1)).format('0,0')}<span className={classes.Display}> hours</span>
                    </span>
                )
                break;
            case 'days':
                displayComponent = (
                    <span className={classes.TimerCountdown}>
                        {numeral(Math.floor(Math.abs(this.props.differenceInSeconds / 86400)) * ((this.props.differenceInSeconds < 0) ? -1 : 1)).format('0,0')}<span className={classes.Display}> days</span>
                    </span>
                )
                break;
            case 'weeks':
                displayComponent = (
                    <span className={classes.TimerCountdown}>
                        {numeral(Math.floor(Math.abs(this.props.differenceInSeconds / 86400 / 7)) * ((this.props.differenceInSeconds < 0) ? -1 : 1)).format('0,0')}<span className={classes.Display}> weeks</span>
                    </span>
                )
                break;
            case 'years':
                displayComponent = (
                    <span className={classes.TimerCountdown}>
                        {numeral((Math.floor(Math.abs(this.props.differenceInSeconds / 86400 / 365))) * ((this.props.differenceInSeconds < 0) ? -1 : 1)).format('0,0')}<span className={classes.Display}> years</span>
                    </span>
                )
                break;
            case 'all':
                let years = Math.floor(Math.abs(this.props.differenceInSeconds / YEARS_IN_SECONDS));
                let days = Math.floor(Math.abs(this.props.differenceInSeconds % YEARS_IN_SECONDS / DAYS_IN_SECONDS));
                let hours = Math.floor(Math.abs(this.props.differenceInSeconds % YEARS_IN_SECONDS % DAYS_IN_SECONDS / HOURS_IN_SECONDS));
                let mins = Math.floor(Math.abs(this.props.differenceInSeconds % YEARS_IN_SECONDS % DAYS_IN_SECONDS % HOURS_IN_SECONDS / MINS_IN_SECONDS));
                let seconds = Math.floor(Math.abs(this.props.differenceInSeconds % YEARS_IN_SECONDS % DAYS_IN_SECONDS % HOURS_IN_SECONDS % MINS_IN_SECONDS));
                displayComponent = (
                    <span className={classes.TimerCountdownB}>
                        {numeral(years).format('0,0')}<span className={classes.Display}>y</span> {numeral(days).format('0,0')}<span className={classes.Display}>d</span> {numeral(hours).format('0,0')}<span className={classes.Display}>h</span> {numeral(mins).format('0,0')}<span className={classes.Display}>m</span> {numeral(seconds).format('0,0')}<span className={classes.Display}>s</span>
                    </span>
                )
                break;
            default: break;
        }
        return (
            <div className={classes.CountdownDisplay} onClick={() => (this.props.changeDisplay(this.props.timerId)) }>
                { displayComponent }
            </div>   
        )
    }
}

export default CountdownDisplay;