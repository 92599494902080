import React, { Component } from 'react';
import classes from './homePageContainer.css';
import About from '../../Components/HomePageComponents/AboutPage/about';
import Packages from '../../Components/HomePageComponents/PackagePage/packages';
// import Profile from '../../Components/HomePageComponents/ProfilePage/profile';
// import Tutorial from '../../Components/HomePageComponents/TutorialPage/tutorial';


class HomePageContainer extends Component {
    state = {
        showPage: 'about'
    }

    aboutPageHandler = (showPage) => {
        this.setState({ showPage: 'about' });
    }

    packagesPageHandler = (showPage) => {
        this.setState({ showPage: 'packages' });
    }

    // profilePageHandler = (showPage) => {
    //     this.setState({ showPage: 'profile' });
    // }

    // tutorialPageHandler = (showPage) => {
    //     this.setState({ showPage: 'tutorial' });
    // }

    render() {
        if (this.state.showPage === 'about') {
            return (
                <div className={classes.Container}>
                    {/* < ul className={classes.Header}>
                        <li onClick={() => (this.aboutPageHandler(this.state.showPage))}>About</li>
                        <li onClick={() => (this.packagesPageHandler(this.state.showPage))}>Packages</li>
                        <li onClick={() => (this.tutorialPageHandler(this.showPage))}>Tutorial</li>
                        <li onClick={() => (this.profilePageHandler(this.showPage))}>Profile</li> 
                    </ul> */}
                    <About />
                </div>
            )
        }
    }
}

export default HomePageContainer;


        // if (this.state.showPage === 'tutorial') {
        //     return (
        //         <div className={classes.Container}>
        //             < ul className={classes.Header}>
        //                 <li onClick={() => (this.aboutPageHandler(this.showPage))}>About</li>
        //                 <li onClick={() => (this.packagesPageHandler(this.showPage))}>Packages</li>
        //                 <li onClick={() => (this.tutorialPageHandler(this.showPage))}>Tutorial</li>
        //                 <li onClick={() => (this.profilePageHandler(this.showPage))}>Profile</li>
        //             </ul>
        //             < Tutorial />
        //         </div>
        //     )
        // }
        // if (this.state.showPage === 'profile') {
        //     return (
        //         <div className={classes.Container}>
        //             < ul className={classes.Header}>
        //                 <li onClick={() => (this.aboutPageHandler(this.showPage))}>About</li>
        //                 <li onClick={() => (this.packagesPageHandler(this.showPage))}>Packages</li>
        //                 <li onClick={() => (this.tutorialPageHandler(this.showPage))}>Tutorial</li>
        //                 <li onClick={() => (this.profilePageHandler(this.showPage))}>Profile</li>
        //             </ul>
        //             < Profile />
        //         </div>
        //     )
        // }
        // if (this.state.showPage === 'packages') {
        //     return (
        //         <div className={classes.Container}>
        //             < ul className={classes.Header}>
        //                 <li onClick={() => (this.aboutPageHandler(this.state.showPage))}>About</li>
        //                 <li onClick={() => (this.packagesPageHandler(this.state.showPage))}>Packages</li>
        //                 {/* <li onClick={() => (this.tutorialPageHandler(this.showPage))}>Tutorial</li>
        //                         <li onClick={() => (this.profilePageHandler(this.showPage))}>Profile</li> */}
        //             </ul>
        //             < Packages />
        //         </div>
        //     )
        // }