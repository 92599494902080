import React, { Component } from 'react';
import classes from './timerCard.css';
import TimerImage from '../TimerParts/TimerImages/timerImage';
import CategoryIcon from '../TimerParts/CategoryIcon/categoryIcon';
// import TimerBackSide from '../TimerParts/TimerBackSide/timerBackSide';
import TimerTitle from '../TimerParts/TimerTitle/timerTitle';
import TimerDescription from '../TimerParts/TimerDescription/timerDescription';
import TimerDate from '../TimerParts/TimerDate/timerDate';
import CountdownDisplay from '../TimerParts/CountdownDisplay/countdownDisplay';
import FollowTimer from '../TimerParts/FollowTimer/followTimer';
        
class timerCard2 extends Component {

    flipCardHandler = () => {
        this.style = {
            position: 'relative',
            transform: 'rotateY(180deg)',
            transition: 'transform 0.5s'
        }
    }

    render(){
        return (
            <div className={classes.CardContainer}>
                <div className={classes.Front}>
                    <div className={classes.Header}> Public Event </div>
                    < FollowTimer
                        isFollow={this.props.timer.isFollow}
                        follow={this.props.follow}
                        timerId={this.props.timer.id} />
                    <TimerImage
                        displayImage={this.props.displayImage}
                        className={classes.Image}
                        image={this.props.timer.images[this.props.timer.imageToShow]}
                        timerId={this.props.timer.id} />
                    < TimerTitle
                        title={this.props.timer.title} />
                    < CategoryIcon
                        categories={this.props.timer.categories} />
                    <TimerDate
                        timestamp={this.props.timer.timestamp} />
                    < TimerDescription
                        description={this.props.timer.description} />
                    < CountdownDisplay
                        differenceInSeconds={this.props.timer.differenceInSeconds}
                        display={this.props.timer.display}
                        changeDisplay={this.props.changeDisplay}
                        timerId={this.props.timer.id} />
                    <div className={classes.DetailsButton}>Details Coming Soon</div>
                </div>
            </div>
        )
    }
} 
        
export default timerCard2;

{/* <div className={classes.Back}>
    < TimerBackSide
        flipCard={this.props.flipCard}
        timerId={this.props.timer.id} />
    <ul className={classes.Info}>
        <li>repeat: no</li>
        <li>reminder: 1 week</li>
    </ul>
    <ul className={classes.BottomContainer}>
        <li onClick={() => (this.props.delete(this.props.timerId))} className={classes.Trash}><i class="far fa-trash-alt"></i> Delete Timer </li>
        <li><span className={classes.Trash}><span><i class="fas fa-paint-brush"></i></span> Edit Timer</span></li>
    </ul>
</div> */}