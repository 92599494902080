import React, { Component } from 'react';
import classes from './sampleCountdown.css';
import numeral from 'numeral';
import Pet from '../../../../Assets/DesignElements/pet.jpg';

// numeral.register('locale', 'hu', {
//     delimiters: {
//         thousands: ' ',
//         decimal: ','
//     },
//     abbreviations: {
//         thousand: 'E',  // ezer
//         million: 'M',   // millió
//         billion: 'Mrd', // milliárd
//         trillion: 'T'   // trillió
//     },
//     ordinal: function (number) {
//         return '.';
//     },
//     currency: {
//         symbol: ' Ft'
//     }
// });

class SampleCountdown extends Component {

   state = {
       differenceInSeconds: null,
   }

   componentDidMount = async () => {
       setInterval(this.calculateDifferenceInSeconds, 100);
   }

   calculateDifferenceInSeconds = () => {
       let todayInSeconds = Math.floor(Date.now());
       let dogDay = '2020,00,14';
       let timerDateArray = dogDay.split(',');
       let timerDate = new Date(...timerDateArray);
       let differenceInSeconds = Math.floor((timerDate - todayInSeconds) / 1000);
       this.setState({ differenceInSeconds: differenceInSeconds });
   }
  
   render() {
       numeral.locale('hu');
       return (
           <div className={classes.TimerSample}>
               <img className={classes.SampleImage} src={Pet} alt=''></img>
               <p className={classes.SampleTitle}> Dress Up Your Pet Day </p>
               <p className={classes.SampleDate}>Tue, 14. 01. 2020</p>
               <div className={classes.SampleCountdown}>{numeral(this.state.differenceInSeconds).format()} sec </div>
           </div>
       )
   }
}


export default SampleCountdown;
