import React from 'react';
import classes from './followTimer.css';

const followTimer = (props) => {
    return (
        <div className={classes.Follow}>
            <div onClick={() => (props.follow(props.timerId))}><i className={classes.Heart} class="fas fa-heart" style={{ color: props.isFollow ? "#F76B8A" : "grey" }}></i></div>
            <p className={classes.Available}>User Account: Coming Soon</p>
        </div>
    )
}

export default followTimer;