import React from 'react';
import classes from './employee.css';
// import Dori from '../../../Assets/Icons/profile.jpeg';

const employee = (props) => {
    return (
        <div className={classes.Container}>
            <p className={classes.Title}>Our Story</p>
            <ul className={classes.ListContainer}>
                <li>“At the beginning, I created Pontimer only for myself, as I couldn’t find any countdown timer, that fitted to my needs.</li>
                <li>I tried out many timers, task management tools, event organisers, but I always felt I still missing something.</li>
                <li>I missed a lot of birthdays, holidays, and I also missed out a lot of cool events, what happened around me. </li>
                <li>After a while, my friends started using Pontimer.</li>
                <li>I made a bunch of user interviews and tests in the last year, and Pontimer became alive in 2018.” </li>
                <li className={classes.Italic}>- Dora, founder of Pontimer</li>
            </ul>
        </div>
    )
}

export default employee;