import React from 'react';
import classes from './navigation.css';
import moment from 'moment';
import PuffinLogo from '../../Assets/DesignElements/puffinLogo4.png';

let today = new Date();
let dd = today.getDate();
let mm = today.getMonth() + 1;
let yyyy = today.getFullYear();

// dd < 10 ? dd = '0' + dd : null;
today = dd + '. ' + mm + '. ' + yyyy;
console.log(today);


const navigation = (props) => (
    <div className={classes.Navigation}>
        <div id='Pontimer' className={classes.Logo}><i className="fas fa-kiwi-bird"></i>Pontimer</div>
        <div className={classes.CurrentDateDisplay}>{moment().format("ddd, DD. MM. YYYY")}</div>
    </div>
);


export default navigation;

/* <ul className={classes.Navigation}>

    <li className={classes.ImageLogo}><img src={PuffinLogo} alt=''></img></li>
    <li id='Pontimer' className={classes.Logo}> Pontimer </li>
    <li className={classes.Notification}>Notifications</li>
        <li className={classes.Discovery}>Discovery</li>
        <li className={classes.MyTimers}>My Timers</li>
        <li className={classes.User}>Log in / Register</li>
    <li><span className={classes.CurrentDateDisplay}>{moment().format("ddd, DD. MM. YYYY")}</span></li>
</ul> */