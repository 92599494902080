import React from 'react';
import classes from './bottomNavigationMobile.css';


const bottomNavigationMobile = (props) => {
    return (
        <div className={classes.BottomNavigationMobile}>
            <div className={classes.Container}>
                <span onClick={() => (props.navigationHandler("home"))}><i className="fas fa-kiwi-bird"></i></span>
                <span onClick={() => (props.navigationHandler("discovery"))}><i class="fas fa-globe-americas"></i></span>
                <span onClick={() => (props.navigationHandler("coming"))}><i className="fas fa-plus"></i></span>
            </div>
        </div>
    )
}
export default bottomNavigationMobile;
