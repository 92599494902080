import React from 'react';
import classes from './example.css';

import Birthday from '../../../../Assets/DesignElements/birthday.png';
import Medical from '../../../../Assets/DesignElements/medical.png';
import Got from '../../../../Assets/DesignElements/got.png';
import Walk from '../../../../Assets/DesignElements/walk.png';
import Work from '../../../../Assets/DesignElements/dogwork.png';
import Dressup from '../../../../Assets/DesignElements/pet.jpg';



const example = (props) => {
    return (
        <div className={classes.Container}>
            <div className={classes.PontimerContainer}>
                <p className={classes.PontimerTitle}>How does Pontimer work?</p>
                <div className={classes.Types}>
                    <div className={classes.Type1}><span><i class="fas fa-user"></i></span> <br /> <span className={classes.TimerTitle}> Personal Timers  </span><br /> Every event what you create by yourself. It can be any past or future events.</div>
                    <div className={classes.Type2}><span><i class="fas fa-globe-americas"></i></span> <br /> <span className={classes.TimerTitle}>Public Timers </span><br /> Every event what you find in the discovery section. You can add any of these events to your personal collection.</div>
                </div>
                <div className={classes.ExampleContainer}>
                    <div className={classes.ExampleTitle}>Let's assume; you have a dog</div>
                    <div className={classes.SubTitle}><i class="fas fa-plus"></i> You can create beautiful timers for</div>
                    <ul className={classes.Example1}>
                        <li><img className={classes.ExampleImage} src={Birthday} alt='' /> His / Her Birthday Party <br /><span>75 Days</span></li>
                        <li><img className={classes.ExampleImage} src={Medical} alt='' /> The Next Medical Check <br /><span>28 Days</span></li>
                        <li><img className={classes.ExampleImage} src={Got} alt='' /> Since <br /> you got Her/Him <br /><span>- 816 Days</span></li>
                    </ul>
                    <div className={classes.SubTitle}><i class="fas fa-globe-americas"></i> Then, add public timers to you page</div>
                    <ul className={classes.Example2}>
                        <li><img className={classes.ExampleImage} src={Walk} alt='' /> Walk your dog day <br /><span>102 Days</span></li>
                        <li><img className={classes.ExampleImage} src={Dressup} alt='' /> Dress up your pet day <br /><span>308 Days</span></li>
                        <li><img className={classes.ExampleImage} src={Work} alt='' /> Take your dog to work day <br /><span>116 Days</span></li>
                    </ul>
                    <div className={classes.ExampleClosure}>Create and add all dog-related events to know everything that makes both of you happy and updated!</div>
                </div>
                <p> Pontimer is <span className={classes.Highlight}>the only product,</span> where you can manage both personal and public events in one place.</p>
            </div>
        </div>
    )
}

export default example;