import React, {Component} from 'react';
import classes from './timerDate.css';
import moment from 'moment';

class TimerDate extends Component {
    render() {
        let timerDate = new Date(this.props.timestamp * 1000);
        return (
            <div className={classes.TimerDate}>
                {moment(timerDate).format("ddd, DD. MM. YYYY")}
                <span className={classes.TimerTime}> {this.props.TimerTime}</span>
                {/* <span className={classes.TimerTime}> {this.props.TimerTime} | 16:00</span> */}
            </div>
        )
    }
}

export default TimerDate;