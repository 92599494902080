import React from 'react';
import classes from './loadMoreButton.css';
// import Dori from '../../../Assets/Icons/profile.jpeg';

const loadMoreButton = (props) => {
    return (
        <div onClick={() => props.loadMoreClick()} className={classes.LoadMoreButton}> load more ...</div>
    )
}

export default loadMoreButton;