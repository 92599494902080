import React from 'react';
import classes from './scrollUp.css';

const scrollUp = (props) => {
    return (
        <div onClick={() => props.scrollUp()} className={classes.Container}>
            <a href='#Pontimer'> <i class="fas fa-arrow-up"></i> </a>
        </div>
    )
}

export default scrollUp;