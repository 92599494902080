import React, {Component} from 'react';
import classes from './categoryNavigation.css';

class CategoryNavigation extends Component {
    render() {
        return (
            <div className={classes.Container}>
                <div onClick={() => this.props.categoryClick()}> all</div>
                <div onClick={() => this.props.categoryClick(16)}><span><i className="fas fa-paw"></i></span> animals </div>
                <div onClick={() => this.props.categoryClick(18)}><span><i class="fas fa-theater-masks"></i></span> art </div>
                <div onClick={() => this.props.categoryClick(21)}><span><i className="fas fa-book-reader"></i></span> books & comics</div>
                <div onClick={() => this.props.categoryClick(22)}><span><i className="fas fa-gamepad"></i></span> games </div>
                <div onClick={() => this.props.categoryClick(15)}><span><i className="fas fa-landmark"></i></span> history </div>
                <div onClick={() => this.props.categoryClick(2)}><span><i className="fas fa-snowman"></i></span> holidays </div>
                <div onClick={() => this.props.categoryClick(7)}><span><i className="fas fa-video"></i></span> movie & tv </div>
                <div onClick={() => this.props.categoryClick(17)}><span><i className="fas fa-guitar"></i></span> music </div>
                <div onClick={() => this.props.categoryClick(19)}><span><i class="fab fa-pagelines"></i></span> nature </div>
                <div onClick={() => this.props.categoryClick(20)}><span><i className="fas fa-rocket"></i></span> science </div>
                <div onClick={() => this.props.categoryClick(6)}><span><i className="fas fa-basketball-ball"></i></span> sport </div>
                {/* <div
           className={classes.TrashCategory}
           onClick={() => props.categoryClick(100)}><span><i class="far fa-trash-alt"></i></span> trash
       </div> */}
            </div>
        )
    }
}

export default CategoryNavigation;
