import React from "react";
import classes from "./categoryIcon.css";

const categoryIcon = props => (
  <div className={classes.Category}>
    {props.categories.map(category => {
      switch (category) {
        case 1:
          return (
            <span className={classes.Category}>
              <i class="fas fa-suitcase-rolling"/> Travel
            </span>
          );
        case 2:
          return (
            <span className={classes.Category}>
              <i class="fas fa-gift" /> Holiday
            </span>
          );
        case 3:
          return (
            <span className={classes.Category}>
              <i class="fas fa-birthday-cake" /> Birthday
            </span>
          );
        case 4:
          return (
            <span className={classes.Category}>
              <i class="fas fa-building" /> Work
            </span>
          );
        case 5:
          return (
            <span className={classes.Category}>
              <i class="fas fa-bus" /> School
            </span>
          );
        case 6:
          return (
            <span className={classes.Category}>
              <i class="fas fa-basketball-ball" /> Sport
            </span>
          );
        case 7:
          return (
            <span className={classes.Category}>
              <i class="fas fa-video" /> Movie
            </span>
          );
        case 8:
          return (
            <span className={classes.Category}>
              <i class="fas fa-pizza-slice" /> Food
            </span>
          );
        case 9:
          return (
            <span className={classes.Category}>
              <i class="fas fa-tooth" /> Helath & Beatuy
            </span>
          );
        case 10:
          return (
            <span className={classes.Category}>
              <i class="fas fa-paw" /> Pet
            </span>
          );
        case 11:
          return (
            <span className={classes.Category}>
              <i class="fas fa-home" /> House&Car
            </span>
          );
        case 12:
          return (
            <span className={classes.Category}>
              <i class="fas fa-cut" /> Hobby
            </span>
          );
        case 13:
          return (
            <span className={classes.Category}> Other </span>
          );
        case 14:
          return (
            <span className={classes.Category}>
              <i class="fas fa-heart" /> Couple
            </span>
          );
        case 15:
          return (
            <span className={classes.Category}>
              <i class="fas fa-university" /> History
            </span>
          );
        case 16:
          return (
            <span className={classes.Category}>
              <i class="fas fa-paw" /> Animals
            </span>
          );
        case 17:
          return (
            <span className={classes.Category}>
              <i class="fas fa-guitar" /> Music
            </span>
          );
        case 18:
          return (
            <span className={classes.Category}>
              <i class="fas fa-theater-masks" /> Art
            </span>
          );
        case 19:
          return (
            <span className={classes.Category}>
              <i class="fab fa-pagelines" /> Nature
            </span>
          );
        case 20:
          return (
            <span className={classes.Category}>
              <i class="fas fa-rocket" /> Science
            </span>
          );
        case 21:
          return (
            <span className={classes.Category}>
              <i class="fas fa-book-reader" /> Books
            </span>
          );
        case 22:
          return (
            <span className={classes.Category}>
              <i class="fas fa-gamepad"></i> Games
            </span>
          );
        case 100:
          return (
            <span className={classes.Category}>
              <i class="far fa-trash-alt" /> Deleted Timer
            </span>
          );
        default:
          return (
            <span className={classes.DefaultCategory}>
              <i class="fas fa-paw" /> Animals
            </span>
          );
      }
    })}
  </div>
);

export default categoryIcon;
