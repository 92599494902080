import React, { Component } from "react";
import axios from "axios";
import classes from "./App.css";

import Navigation from "./Navigation/DesktopNavigation/navigation";
import BottomNavigation from "./Navigation/BottomNavigation/bottomNavigation";
import BottomNavigationMobile from './Navigation/MobileNavigation/bottomNavigationMobile';
import CategoryNavigation from './Navigation/CategoryNavigation/categoryNavigation';
import ScrollUp from './Navigation/ScrollUpNavigation/scrollUp';

import TimerContainer from "./Containers/TimerContainer/timerContainer";
import LoadMoreButton from "./Components/LoadMoreButton/loadMoreButton";
import HomePageContainer from './Containers/HomePageContainer/homePageContainer';
import ComingSoon from './Components/ComingSoon/comingSoon';

const TIMER_SERVICE_URI = 'https://1a3daffypk.execute-api.eu-west-1.amazonaws.com/prod';

class App extends Component {
    state = {
      timers: [],
      page: "discovery",
      pagination: {
        hasMore: false,
        page: 0,
        category: ""
      },
      width: window.innerWidth,
    }

  getTimers = async (category = "", page = 0) => {
    const response = await axios.get(
      `${TIMER_SERVICE_URI}/api/v1/timers?category=${category}&page=${page}`
    );
    const timers = response.data.records.map(timer => ({
      ...timer,
      isFollow: false,
      imageToShow: 0
    }));
    const hasMore = response.data.metadata.totalCount > ((response.data.metadata.page + 1) * response.data.metadata.pageSize);
    // console.log(hasMore);
    return {
      timers,
      hasMore
    };
  };

  categoryClickHandler = async (category = "") => {
    const { timers, hasMore } = await this.getTimers(category);
    this.setState({ pagination: {
      hasMore,
      page: 0,
      category
    }});
    this.setState({ timers: timers });
  };

  loadMoreTimers = async () => {
    if (this.state.pagination.hasMore) {
      const { timers, hasMore } = await this.getTimers(this.state.pagination.category, this.state.pagination.page + 1);
      this.setState({ pagination: {
        hasMore,
        page: this.state.pagination.page + 1,
        category: this.state.pagination.category
      }});
      this.setState({ timers: [...this.state.timers].concat(timers) });
    }
  };

  componentWillMount() {
    window.addEventListener('resize', this.windowSizeChangeHandler);
  };

  componentDidMount = async () => {
    const {timers, hasMore} = await this.getTimers();
    this.setState({ timers: timers });
    this.setState({ pagination: {
      hasMore,
      page: 0,
      category: ""
    }});
    setInterval(this.calculateDifferenceInSeconds, 100);
  };

  windowSizeChangeHandler = () => {
    this.setState({ width: window.innerWidth });
  };

  changeDisplay = id => {
    const displayOptions = [
      "seconds",
      "minutes",
      "hours",
      "days",
      "weeks",
      "years",
      "all",
      "heartbeats"
    ];
    let timers = [...this.state.timers];
    let timer = timers.find(timer => timer.id === id);
    let displayIndex = displayOptions.findIndex(
      display => display === timer.display
    );
    timer.display = displayOptions[(displayIndex + 1) % displayOptions.length];
    timers.map(stateTimer => {
      if (stateTimer.id === timer.id) {
        stateTimer.display = timer.display;
      }
      return stateTimer;
    });
    this.setState({ timers: timers });
  };

  calculateDifferenceInSeconds = () => {
    let todayInSeconds = Math.floor(Date.now());
    let timers = [...this.state.timers];
    timers = timers.map(timer => {
      timer.differenceInSeconds = Math.floor(
        (timer.timestamp * 1000 - todayInSeconds) / 1000
      );
      return timer;
    });
    this.setState({ timers: timers });
  };

  imageToShowHandler = id => {
    let timers = this.state.timers.map(timer => {
      if (timer.id === id) {
        let imageArray = timer.images.length - 1;
        if (timer.imageToShow < imageArray) {
          timer.imageToShow++;
        } else if (timer.imageToShow >= imageArray) {
          timer.imageToShow = 0;
        }
      }
      return timer;
    });
    this.setState({ timers: timers });
  };

    followDiscoveryTimerHandler = id => {
    let timers = this.state.timers.map(timer => {
      if (timer.id === id) {
        timer.isFollow = !timer.isFollow;
      }
      return timer;
    });
    this.setState({ timers: timers });
  };

  navigationHandler = (page) => {
    this.setState({ page: page});
  }

  scrollUpHandler = () => {
    window.scrollTo(0, 0);
  }

  render() {
    const isMobile = window.innerWidth <= 500;
        if (this.state.page === "home") {
            return (
              < div className={classes.App} >
                < HomePageContainer />
                < BottomNavigationMobile
                  navigationHandler={this.navigationHandler} />
              </div >
            );
          }
        if (this.state.page === "coming") {
          return (
            < div className={classes.App} >
              < ComingSoon />
              < BottomNavigationMobile
                  navigationHandler={this.navigationHandler} />
            </div >
          );
        }
      if (this.state.page === "discovery") {
        if (isMobile) { 
          return (
            <div className={classes.App}>
              < Navigation click={this.landingpageHandler} />
              < CategoryNavigation categoryClick={this.categoryClickHandler} />
              < ScrollUp scrollUp={this.scrollUpHandler} />
              < TimerContainer
                follow={this.followDiscoveryTimerHandler}
                displayImage={this.imageToShowHandler}
                timers={this.state.timers}
                changeDisplay={this.changeDisplay} />
              {this.state.pagination.hasMore &&
                <LoadMoreButton loadMoreClick={this.loadMoreTimers} />
              }
              < BottomNavigationMobile
                  navigationHandler={this.navigationHandler} />
            </div>
          );
        } else {
          return (
          <div className={classes.App}>
            < Navigation click={this.landingpageHandler} />
            < CategoryNavigation categoryClick={this.categoryClickHandler} />
            < ScrollUp scrollUp={this.scrollUpHandler} />
            < TimerContainer
              follow={this.followDiscoveryTimerHandler}
              displayImage={this.imageToShowHandler}
              timers={this.state.timers}
              changeDisplay={this.changeDisplay} />
            {this.state.pagination.hasMore &&
              <LoadMoreButton loadMoreClick={this.loadMoreTimers} />
            }
            < BottomNavigation />
          </div>
          )
        }
      }
  }
}

export default App;

// deleteTimerHandler= (id) => {
//   console.log('to delete');
//   let timers = [...this.state.timers];
//   timers = timers.filter((timer) => {
//     if (timer.id !== id) {
//       return timer;
//     }
//     });
//   this.setState({timers: timers});
// }



// cancelCreatePanelHandler = (page) => {
//   this.setState({ page: 'myTimers' });
// }

// finishCreatePanelHandler = (page) => {
//   this.setState({ page: 'myTimers' });
// }

    // if (this.state.page === 'myTimers') {
    //   return (
    //     <div className={classes.App}>
    //         < Navigation click={this.landingpageHandler} />
    //         < SubNavigation
    //         timerLimit={this.state.account.timerLimit}
    //         createdTimer={this.state.account.timerCreated}
    //         backgroundChanger={this.changeBackgroundHandler}/>
    //         < MobileHeader
    //           page={this.state.page} />
    //         < SubNavigation />
    //         < CategoryNavigation
    //           categoryClick={this.categoryClickHandler} />
    //         < TimerContainer
    //           follow={this.followDiscoveryTimerHandler}
    //           displayImage={this.imageToShowHandler}
    //           flipCard={this.imageBacksideHanlder}
    //           timers={this.state.timers}
    //           delete={this.deleteTimerHandler}
    //           category={this.categoryDisplayHandler}
    //           // percentage={this.state.progressBar}
    //           timerTime={this.state.timerTime}
    //           changeDisplay={this.changeDisplay} />
    //         < LoadMoreButton />
    //         < BottomNavigationMobile
    //           createTimer={this.createTimerHandler}
    //           switchLandingPage={this.mobileLandingPageHandler}
    //           discoveryPage={this.discoveryPageHandler}
    //           myTimersPage={this.myTimersPageHandler} />
    //       </div>
    //     )
    //   } 

    //  if (this.state.page === 'createTimer') {
    //   return (
    //     <div className={classes.App}>
    //         < MobileHeader
    //           page={this.state.page} />
    //         {/* < Navigation click={this.landingpageHandler} /> */}
    //         < CreateTimerContainer
    //           timerLimit={this.state.account.timerLimit}
    //           timerLeft={this.state.account.timerLeft}
    //           isBackdrop={this.state.isBackdrop}
    //           page={this.state.page}
    //           cancelCreatePanel={this.cancelCreatePanelHandler}
    //           finishCreatePanel={this.finishCreatePanelHandler} />
    //         {/* < BottomNavigation /> */}
    //         < BottomNavigationMobile
    //           createTimer={this.createTimerHandler}
    //           switchLandingPage={this.mobileLandingPageHandler}
    //           discoveryPage={this.discoveryPageHandler}
    //           myTimersPage={this.myTimersPageHandler} />
    //       </div>
    //   )
    //  }
    


