import React from 'react';
import classes from './about.css';
import Employee from './Employee/employee';
import SampleCountdown from './SampleCountdown/sampleCountdown';
import Example from './Example/example';

import Contact from '../../../Assets/DesignElements/contact.jpg';
import Puffin from '../../../Assets/DesignElements/puffin2.gif';
import Timer from '../../../Assets/DesignElements/timer.jpg';

const about = (props) => {
    return (
        <div className={classes.Container}>
            <div className={classes.TitleContainer}>
                <p className={classes.Main}>Pontimer <br /> The Countdown Timer</p>
            </div>
            <ul className={classes.MainSubTitle}>
                <li><i class="fas fa-check"></i>Collect</li>
                <li><i class="fas fa-check"></i>Countdown</li>
                <li><i class="fas fa-check"></i>Have fun</li>
            </ul>
            < SampleCountdown />
            <div className={classes.ValueContainer}>
                <p className={classes.ValueTitle}>Get More with Pontimer</p>
                <p>We created Pontimer to be more than just an average countdown timer. By using it, we provide you the following values:</p>
                <div className={classes.Value}>
                    <p className={classes.Value1}><span className={classes.DontMissIcon}><i class="far fa-calendar-alt"></i></span><br />Don't miss an important event!</p>
                    <p className={classes.Value2}><span className={classes.HappyIcon}><i class="far fa-smile-beam"></i></span><br />Be happy and smile a lot!</p>
                </div>
                <p>There is nothing similar to Pontimer out there. <br /> We are mixing the core value of countdown timers with fun and joy. Noone want's to use another boring app, right?</p>
            </div>
            <div className={classes.WhatContainer}>
                <p className={classes.WhatTitle}>What is a Countdown Timer?</p>
                {/* <p>Not a calendar, not a task manager. <br /> A countdown timer is similar to a calendar, but it focusing not only the event itself, but the remaining time. People usually don't like task managers and calendars, as they simbolize obligation. I'm sure, you also have tasks like paying bills, or studying, but you deserve to manage these boring and sometimes painful events with fun! Pontimer helps you to:</p> */}
                <p><span><i class="fas fa-check"></i></span>not boring as a calendar</p>
                <p><span><i class="fas fa-check"></i></span>funnier than a task manager</p>
                <p><span><i class="fas fa-check"></i></span>apllies reminders and repeating</p>
                <p><span><i class="fas fa-check"></i></span>help you to remain organized</p>
                <p><span><i class="fas fa-check"></i></span>increases your happiness level</p>
                <img className={classes.ImageTimer} src={Timer} alt=''></img>
                <p className={classes.Credit}>image from Wa'JS from Dribbble</p>
            </div>
            < Example />
            <div className={classes.PuffinContainer}>
                <p className={classes.PuffinTitle}> Why Puffins?</p>
                <ul className={classes.PuffinText}>
                    <li className={classes.TextHighlight}>Birds are amazing animals with their inner timer which triggers them to fly over another continent and to come back.</li>
                    <li>Among birds, we adore Puffins the most. They are cute, funny looking fellows, with a lovely appearance.</li>
                    <li>Please give a warm welcome to <span className={classes.Bold}>Ponti</span>, who is our mascot.</li>
                </ul>
                <img className={classes.Puffin} src={Puffin} alt=''></img>
                <p className={classes.Credit}>image from Luke Harrison, Dribbble</p>
            </div>
            < Employee />
            <div className={classes.ContactContainer}>
                <p className={classes.ContactTitle}>Contact Details</p>
                <p>Need any help? Want to leave a feedback?</p>
                <a className={classes.Email} href="mailto: hello@pontimer.com"> Contact us: hello@pontimer.com</a>
                <a className={classes.Facebook} href='https://www.facebook.com/coutndowntimer/?modal=admin_todo_tour' rel='noopener noreferrer' target='_blank'><span><i class="fab fa-facebook-f"></i></span> Pontimer Facebook Page</a>
                <img className={classes.Images} src={Contact} alt=''></img>
                <p className={classes.Credit}>Image from Brucira, Dribbble</p>
            </div>
        </div>
    )
}

export default about;